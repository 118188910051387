<template>
    <div>
        <pay-box title="搭配室页面信息设置">
            <div class="info-box">
                <div class="left">
                    <div class="info-row">
                        <div class="label">当前子管理员</div>
                        <div class="name">{{ adminName }}</div>
                    </div>
                    <div class="info-row">
                        <div class="label">专属服务人员名称</div>
                        <div class="input-box">
                            <input type="text" v-model="full_name">
                        </div>
                    </div>
                    <div class="info-row">
                        <div class="label">专属服务口号</div>
                        <div class="input-box">
                            <!-- 修改专属服务口号输入框 -->
                            <input type="text" v-model="service_slogan">
                        </div>
                    </div>
                    <div class="info-row">
                        <div class="label" style="margin-bottom: 26px;">专属服务人员头像</div>
                        <div class="upload-box">
                            <!-- <div class="add">
                                <img src="../../../../public/assets/images/admin-info/add.png">
                            </div> -->
                            <upload-image v-model="avatar" @change="uploadChange1" />
                            <div class="tips">不上传则使用默认形象管理师背景图；图片推荐比例 1:1</div>
                        </div>
                    </div>
                </div>
                <div class="right">
                    <img src="../../../../public/assets/images/admin-info/bg.png">
                    <div class="tips">
                        <span>*</span>
                        <span>示例图</span>
                    </div>
                </div>
            </div>
            <div class="info-row">
                <div class="label" style="margin-bottom: 26px;">专属服务人员背景图</div>
                <div class="upload-box">
                    <upload-image v-model="background_image" @change="uploadChange2" />
                    <div class="tips">不上传则使用默认形象管理师背景图；图片推荐尺寸 1125x900</div>
                    <!-- <div class="add">
                        <img src="../../../../public/assets/images/admin-info/add.png">
                    </div>
                    <div class="tips">不上传则使用默认形象管理师背景图；图片推荐尺寸 1125x900</div> -->
                </div>
            </div>
            <div class="info-row" style="width: 100%;">
                <div class="label">专属服务人员履历标签</div>
                
                <div class="input-box">
                    <img src="../../../../public/assets/images/admin-info/1.png">
                    <input type="text" v-model="tag1">
                </div>

                <div class="input-box" style="margin-left: 14px;">
                    <img src="../../../../public/assets/images/admin-info/2.png">
                    <input type="text" v-model="tag2">
                </div>
            </div>
        </pay-box>

        <div class="btn-box">
            <button class="btn" @click="submit">保存</button>
        </div>
    </div>
</template>

<script>
import api from "@/repo/api";
import PayBox from "@/views/AST/Pay/Step/PayBox.vue";
import UploadImage from "@/components/UploadImage.vue";
export default {
    name: "AdminInfo",
    components: {
        PayBox,
        UploadImage
    },
    data() {
        return {
            routeMatch:"/ast/xcx/admin",
            member_id: 0,
            full_name: "",
            adminName: "",
            service_slogan: "",
            tag1: "",
            tag2: "",
            background_image:"",
            avatar:""
        }
    },
    mounted() {
        let params = this.$route.query;
        console.log(params);
        if (params.id) {
            this.member_id = params.id;
        }
        if (params.name) {
            this.adminName = params.name;
        }
        this.getAdminInfo(params.id);
    },

    methods: {
        uploadChange1(e) {
            console.log(e);
            this.avatar = e; 
        },
        uploadChange2(e) {
            console.log(e);
            this.background_image = e;
        },
        submit() {
            if (!this.full_name) {
                this.$message.error("请输入专属服务人员名称");
                return;
            }
            if (!this.service_slogan) {
                this.$message.error("请输入专属服务口号");
                return;
            }
            if (!this.avatar) {
                this.$message.error("请上传专属服务人员头像");
                return;
            }
            if (!this.background_image) {
                this.$message.error("请上传专属服务人员背景图");
                return; 
            }
            if (!this.tag1) {
                this.$message.error("请输入专属服务人员履历标签1");
                return;
            }
            if (!this.tag2) {
                this.$message.error("请输入专属服务人员履历标签2");
                return; 
            }
            if (this.full_name.length > 10) {
                this.$message.error("专属服务人员名称不能超过10个字");
                return;
            }
            if (this.service_slogan.length > 16) {
                this.$message.error("专属服务口号不能超过16个字");
                return;
            }
            if (this.tag1.length > 12) {
                this.$message.error("专属服务人员履历标签1不能超过12个字");
                return; 
            }
            if (this.tag2.length > 12) {
                this.$message.error("专属服务人员履历标签2不能超过12个字");
                return;
            }
            let params = {
                member_id: this.member_id,
                full_name: this.full_name,
                service_slogan: this.service_slogan,
                resume_tags: [this.tag1, this.tag2],
                background_image: this.background_image,
                avatar: this.avatar
            }
            console.log(params);
            api.post('/ast-master/sync-master-profile',params, res => {
                if (res.code === 0) {
                    this.$message.success("保存成功");
                    this.$router.back();
                }else {
                    this.$message.error(res.msg);
                }

            })
        },
        getAdminInfo() {
            let params = {
                member_id: this.member_id,
            }
            api.get('/ast-master/master-profile',params, res => {
                if (res.code === 0) {
                    this.full_name = res.data.full_name;
                    this.avatar = res.data.avatar;
                    if (res.data.service_slogan) {
                        this.service_slogan = res.data.service_slogan;
                    }
                    if (res.data.background_image) {
                        this.background_image = res.data.background_image; 
                    }
                    if (res.data.resume_tags && res.data.resume_tags[0]) {
                        this.tag1 = res.data.resume_tags[0];
                    }
                    if (res.data.resume_tags && res.data.resume_tags[1]) {
                        this.tag2 = res.data.resume_tags[1];
                    }
                }else {
                    this.$message.error(res.msg);
                }
            })
        }
    }
}
</script>

<style scoped lang="less">
.btn-box {
    margin-top: 24px;
}

.info-box {
    display: flex;

    .right {
        margin-left: 24px;
        width: 375px;
        height: 210px;

        img {
            width: 100%;
            height: 100%;
        }

        .tips {
            width: 100%;
            text-align: center;
            margin-top: 8px;

            span {
                color: #333;
                font-size: 14px;
                font-weight: bold;

                &:nth-child(1) {
                    color: #FF0000;
                }
            }
        }
    }
}

.info-row {
    width: 500px;
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    .label {
        width: 84px;
        color: #333;
        font-size: 14px;
        margin-right: 16px;
        text-align: right;
    }

    .name {
        height: 32px;
        line-height: 32px;
        color: #fff;
        text-align: center;
        font-size: 14px;
        background: linear-gradient(90deg, #4E8F73 0%, #275C45 100%);
        border-radius: 4px;
        padding: 0 10px;
    }

    .input-box {
        width: 387px;
        height: 40px;
        background: #FFFFFF;
        border: 1px solid #EEEEEE;
        padding: 0 12px;
        box-sizing: border-box;
        display: flex;
        align-items: center;

        img {
            width: 16px;
            height: 17px;
            margin-right: 12px;
        }

        input {
            width: 100%;
            height: 100%;
            border: none;
            outline: none;
        }
    }

    .upload-box {
        .add {
            width: 64px;
            height: 64px;
            border: 1px solid #EEEEEE;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 16px;
                height: 16px;
            }
        }

        .tips {
            color: #999;
            font-size: 12px;
            margin-top: 12px;
        }
    }
}
</style>